import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
// import { attachTokenInterceptor } from '../../../utils/api/axiosInstance';
import { auth } from '../api/authAPI';
import { refreshToken } from '../api/userAPI';
import User, { UserInt } from '../models/user';

export const USER_SLICE_NAME = 'user';

export const loginThunk = createAsyncThunk<
  any,
  { credentials: { email: string; password: string }; rememberMe: boolean },
  { state: RootState }
>('user/login', async (credentials, thunkAPI) => {
  const { config } = thunkAPI.getState();
  const {
    credentials: { email, password },
    rememberMe,
  } = credentials;
  if (config.data) {
    try {
      const usernameLabel = config.data.authConfig.requestFieldName.username;
      const passwordLabel = config.data.authConfig.requestFieldName.password;
      interface loginBody {
        [key: string]: string;
      }
      const body: loginBody = {
        [usernameLabel]: email,
        [passwordLabel]: password,
      };
      const path = config.data.authConfig.methods.local.path;

      const response = await auth(path, body);
      if (rememberMe) {
        User.persistUser(response.token);
      } else {
        User.persistSession(response.token);
      }

      // console.log('Dashboard token state: ', response.token);

      // attachTokenInterceptor(response.token);
      return {
        token: response.token,
        publicKey: config.data.authConfig.jwtPublicKey,
      };
    } catch (error) {
      // console.log(error)
      throw error;
    }
  }
});

export const refreshTokenThunk = createAsyncThunk<
  any,
  string,
  { state: RootState }
>('user/refreshToken', async (token, thunkAPI) => {
  const { config } = thunkAPI.getState();
  try {
    if (config.data) {
      const path = config.data.authConfig.refreshPath;
      const response = await refreshToken(path, token);

      if (response) {
        User.persistUser(response);
        return {
          token: response,
          publicKey: config.data.authConfig.jwtPublicKey,
        };
      }
    }

    User.logoutUser();
    return;
  } catch (error) {
    User.logoutUser();
    return;
  }
});

export const registerThunk = createAsyncThunk<
  any,
  {
    credentials: {
      email: string;
      password: string;
      username: string;
      nickname?: string;
    };
    rememberMe: boolean;
  },
  { state: RootState }
>('user/register', async (credentials, thunkAPI) => {
  const { config } = thunkAPI.getState();
  const {
    credentials: { email, password, nickname, username },
    rememberMe,
  } = credentials;
  if (config.data) {
    try {
      const usernameLabel = config.data.authConfig.requestFieldName.username;
      const passwordLabel = config.data.authConfig.requestFieldName.password;
      interface registerBody {
        [key: string]: string | undefined;
      }
      const body: registerBody = {
        [usernameLabel]: username,
        [passwordLabel]: password,
        email,
        nickname,
      };
      const path = config.data.authConfig.methods.local.registerPath;

      const response = await auth(path, body);
      // console.log('Register res: ', response);
      // if (rememberMe) {
      //   User.persistUser(response.token);
      // }
      return {
        token: response.token,
        publicKey: config.data.authConfig.jwtPublicKey,
      };
    } catch (error) {
      // console.log(error)
      throw error;
    }
  }
});

interface SliceDataState {
  // loading: boolean;
  data: {
    token?: string;
    user?: UserInt;
  };
}

const initialState: SliceDataState = {
  // loading: false,
  data: {},
};

export const userSlice = createSlice({
  name: USER_SLICE_NAME,
  initialState,
  reducers: {
    addUserData: (state, action) => {
      // state.data.token = action.payload as string;
      const { decodedUser } = new User(
        action.payload.token,
        action.payload.publicKey
      );
      // console.log('User: ', decodedUser);
      return {
        ...state,
        data: {
          ...state.data,
          user: decodedUser as UserInt,
          token: action.payload.token,
        },
      };
      // state.data.user = decodedUser as UserInt;
      // state.loading = false;
    },
    logout: (state) => {
      delete state.data.token;
      delete state.data.user;
      localStorage.removeItem('activePortals');
      User.logoutUser();
    },
  },
  extraReducers: (builder) => {
    // builder.addCase(loginThunk.pending, (state) => {
    //   state.loading = true;
    // });
    builder.addCase(loginThunk.fulfilled, userSlice.caseReducers.addUserData);
    builder.addCase(loginThunk.rejected, (error) => {
      console.log(error);
    });
    builder.addCase(
      registerThunk.fulfilled,
      userSlice.caseReducers.addUserData
    );
    // builder.addCase(refreshTokenThunk.pending, (state) => {
    //   state.loading = true;
    // });
    builder.addCase(
      refreshTokenThunk.fulfilled,
      userSlice.caseReducers.addUserData
    );
  },
});

export const { logout, addUserData } = userSlice.actions;

export default userSlice.reducer;
