import Modal from '../../general/components/Modal';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import { useAppStoreDispatch, useAppStoreSelector } from '../../../store';
import { languageEvent } from '../../../events/languageEvent';
import { ChangeEvent, useRef, useState } from 'react';
import { toggleNewPageSwitch } from '../../settings/store';
import axios from 'axios';
import User from '../models/user';
import { useToast } from '../../general/components/Toast.hook';

interface UserSettingsProps {
  isModalShow: boolean;
  setIsModalShow: (show: boolean) => void;
}

const UserSettings = ({ isModalShow, setIsModalShow }: UserSettingsProps) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppStoreDispatch();
  const [error, setError] = useState<string>('');
  const settingsState = useAppStoreSelector((state) => state.settings.data);
  const userState = useAppStoreSelector((state) => state.user.data);
  const token = User.loadUserToken();
  const [triggerMessageHandler] = useToast();
  // const portalState = useAppStoreSelector((state) => state.portal);

  const onLanguageSelectChangeHandler = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    event.preventDefault();
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
    window.dispatchEvent(languageEvent(selectedLanguage));
    // portalState.data.languageEvent.next(selectedLanguage);
  };

  const onNewPageSwitchHandler = (event: ChangeEvent<HTMLInputElement>) => {
    // console.log(event.target.checked);
    dispatch(toggleNewPageSwitch(event.target.checked));
  };

  const passwordRef = useRef<HTMLInputElement>(null);
  const confirmPasswordRef = useRef<HTMLInputElement>(null);

  const validatePassword = (password: string) => {
    const passwordLengthValid = password.length >= 8;
    const hasUppercase = /[A-Z]/.test(password);

    if (!passwordLengthValid) {
      return 'Password must be at least 8 characters long.';
    }
    if (!hasUppercase) {
      return 'Password must contain at least one uppercase letter.';
    }
    return ''; // No error
  };

  const handleClick = async () => {
    const password = passwordRef.current ? passwordRef.current.value : '';
    const confirmPassword = confirmPasswordRef.current ? confirmPasswordRef.current.value : '';

    // Reset errors on each click
    setError('');

    const passwordError = validatePassword(password);
    if (passwordError) {
      setError(passwordError);
      return; // Stop further processing if validation fails
    }

    // Check if passwords match
    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    const username = userState.user?.username;
    await axios.post('/api/user/updatePassword', { username, password }, {headers: {"Authorization" : `Bearer ${token}`}}).then((res) => {
      if (res.data.success) {
        setIsModalShow(false);
        triggerMessageHandler("Password changed", false);
      } else {
        console.error('Error: ', res.data.message);
      }
    });
  };

  return (
    <Modal
      show={isModalShow}
      onHide={() => setIsModalShow(false)}
      title={t('settings.userSettings')}
      footer={null}
    >
      <Form.Group className="ps">
        <Form.Label>{t('settings.language')}</Form.Label>
        <Form.Select
          onChange={onLanguageSelectChangeHandler}
          value={i18n.language}
        >
          <option value="en">EN</option>
          <option value="de">DE</option>
        </Form.Select>
        {/* <div style={{ width: '100%' }}> */}
        <Form.Check
          type="switch"
          id="custom-switch"
          label="Open portals in new page"
          style={{ margin: '.5rem .3rem' }}
          onChange={onNewPageSwitchHandler}
          checked={settingsState?.isNewPageChecked}
          // disabled
        />
        {/* </div> */}
      </Form.Group>
      <Form.Group className='ps'>
        <Form.Label>Change Password</Form.Label>
        <Form.Text dangerouslySetInnerHTML={{__html: 'New Password'}}/>
        <Form.Control type='password' ref={passwordRef}  className='password'/>
        <Form.Text dangerouslySetInnerHTML={{__html: 'Confirm Password'}}/>
        <Form.Control type='password' ref={confirmPasswordRef}  className='password-confirm'/>
        <br/>
        {error && <div className="text-danger mb-2">{error}</div>}
        <Form.Control type='button' value='Apply' className='btn' onClick={handleClick}/>
      </Form.Group>
    </Modal>
  );
};

export default UserSettings;
