import { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAppStoreDispatch } from '../../../store';
import axios from '../../../utils/api/axiosInstance';
import { addSelectedUser } from '../store';
import styles from './Users.module.scss';

function Users() {
  const [users, setUsers] = useState<string[]>([]);
  const navigate = useNavigate();
  const dispatch = useAppStoreDispatch();
  useEffect(() => {
    async function fetchAllUsers() {
      const results = await axios.get('api/admin/users');
      setUsers(results.data as [string]);
    }

    fetchAllUsers();
  }, []);

  const onUserRowClickHandler = (user: string) => {
    //console.log(user);
    dispatch(addSelectedUser(user));
    navigate(`user/${user}`);
  };

  return (
    <div className={styles['users-container']}>
      <Table striped bordered hover size="md">
        <thead>
          <tr>
            <th> </th>
            <th>Username</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, index) => (
            <tr
              onClick={() => onUserRowClickHandler(user)}
              key={index}
              style={{ cursor: 'pointer' }}
            >
              <td>{index}</td>
              <td>{user}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default Users;
