import { Row } from 'react-bootstrap';
import { useAppStoreDispatch, useAppStoreSelector } from '../../store';
import styles from './Dashboard.module.scss';
import Widget from './components/Widget';
import LastVisited from './components/LastVisited';
import PortalGroupNav from './components/PortalGroupNav';
import GuestModeLabel from './components/GuestModeLabel';
import { useEffect, useState } from 'react';
import { fetchPortalsThunk, Portals, selectIsGuestMode } from '../portal/store';
import User from '../auth/models/user';
import usePortals from './Dashboard.hook';
import usePageTitle from '../../hooks/usePageTitle';
// import { attachTokenInterceptor } from '../../utils/api/axiosInstance';

function Dashboard() {
  // const portalState = useAppStoreSelector((state) => state.portal);
  const isGuest = useAppStoreSelector(selectIsGuestMode);
  const tokenState = useAppStoreSelector((state) => state.user.data.token);
  const dispatch = useAppStoreDispatch();
  // const [portals, setPortals] = useState<Portals[]>([]);
  const token = User.loadUserToken();
  const [title] = usePageTitle('Dashboard');

  const [portals] = usePortals();

  useEffect(() => {
    // console.log('Dashboard token: ', tokenState);
    if (tokenState) {
      // attachTokenInterceptor(tokenState);
      dispatch(fetchPortalsThunk());
    }
  }, [tokenState]);

  // useEffect(() => {
  //
  //
  //     }, [])
  // console.log(
  //   'Tokens: ',
  //   token,
  //   !!token,
  //   !tokenState,
  //   !!token || !tokenState,
  //   isGuest && (!!token || !tokenState)
  // );

  return (
    <div style={{ padding: '.5rem 1rem' }} className="ps">
      {title}
      {isGuest && (!!!token || !tokenState) && <GuestModeLabel />}
      {(!isGuest || !!token || tokenState) && <LastVisited />}
      {(!isGuest || !!token || tokenState) && <PortalGroupNav />}
      <Row className={styles['portals']}>
        {portals &&
          portals.map((portal, index) => (
            <Widget
              key={`widget-${index}-${portal.portalID}`}
              portal={portal}
            />
          ))}
      </Row>
    </div>
  );
}

export default Dashboard;
