// @ts-ignore
import jwt, { JwtPayload } from 'jsonwebtoken';

export interface UserInt {
  username: string;
  real_name: string;
  nickname: string;
  email: string;
  isSuperuser: boolean;
  iat: number;
  exp: number;
  token: string;
  avatarURL?: string;
  permissions?: string[];
  roles?: any;
}

class User {
  public decodedUser: UserInt | JwtPayload;
  static KEY = 'userToken';
  constructor(public token: string, publicKey: string) {
    this.decodedUser = this.decodeUserToken(publicKey) as UserInt;
    localStorage.setItem('user', JSON.stringify({ 
      username: this.decodedUser.username, 
      real_name: this.decodedUser.real_name,
      isSuperuser: this.decodedUser.isSuperuser,
      groups: this.decodedUser.roles,
    }))
  }

  private decodeUserToken(publicKey: string) {
    try {
      return jwt.verify(this.token, publicKey);
    } catch (error) {
      User.logoutUser();
    }
  }
  static loadUserToken() {
    const cookies = document.cookie.split('; ');
    const tokenCookie = cookies.find(row => row.startsWith('token='))?.split('=')[1];
    return localStorage.getItem(User.KEY) || sessionStorage.getItem(User.KEY) || tokenCookie;
  }

  static persistUser(token: string) {
    localStorage.setItem(User.KEY, typeof token === 'string' ? token : '');
  }

  static persistSession(token: string) {
    sessionStorage.setItem(User.KEY, typeof token === 'string' ? token : '');
  }

  static logoutUser() {
    localStorage.removeItem(User.KEY);
    sessionStorage.removeItem(User.KEY);
    localStorage.removeItem('user');
    sessionStorage.removeItem('user');
    const cookies = document.cookie.split('; ');
    const tokenCookie = cookies.find(row => row.startsWith('token='))?.split('=')[1];
    if (tokenCookie) {
      document.cookie = `token=${tokenCookie}; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      window.location.href = process.env.AUTH_OIDC_LOGOUT_URL || '/';
    }

  }

  static expireUserSession(
    triggerMessageHandler?: (message: string, hasEror: boolean) => void
  ) {
    if (triggerMessageHandler) {
      triggerMessageHandler(
        'Your session has expired, please, login again!',
        true
      );
    }
    User.logoutUser();
  }
}

export default User;
