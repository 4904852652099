import PortalApp from 'portal';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useAppStoreDispatch, useAppStoreSelector } from '../../../store';
import { Events } from '../../../utils/constants';
import { fetchPortalBasedOnId } from '../../app/api/portalAPI';
import User from '../../auth/models/user';
import { useToast } from '../../general/components/Toast.hook';
import {
  addActivePortal,
  fetchPortalsThunk,
  removeSelectedPortal,
  storeImportModalData,
} from '../store';
import CustomIframe from './CustomIframe';
import Launcher from 'toolkit-template';
import usePageTitle from '../../../hooks/usePageTitle';
import Loading from '../../general/components/Loading';
import { truncateString } from '../../../utils/functions';
import { AxiosError } from 'axios';
import { fetchPortals } from '../api/portalAPI';

function PortalRenderer() {
  const [portalConfig, setPortalConfig] = useState<any>(null);
  const [selectedFunction, setSelectedFunction] = useState<string | null>(null);
  const [parameterData, setParameterData] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const portalId = +(useLoaderData() as string);
  const { i18n } = useTranslation();
  const dispatch = useAppStoreDispatch();
  const [triggerMessageHandler] = useToast();
  const navigate = useNavigate();
  const portalState = useAppStoreSelector((state) => state.portal);
  const portal = portalState.data.portals.find((p) => p.portalID === portalId);
  // console.log('Portal selected: ', portal);
  // const isFirstRender = useRef(true);
  const portalName = portal?.portalName;
  const [title] = usePageTitle(
    portalName ? truncateString(portalName, 10) : 'Portal'
  );
  const guestPortalsState = useAppStoreSelector(
    (state) => state.portal.data.guestPortals
  );

  useEffect(() => {
    const fetchPortalConfig = async () => {
      setIsLoading(true);
      const isPortalGuest = guestPortalsState?.findIndex(
        (portal) => portal.portalID === portalId
      );
      const isGuest = isPortalGuest === -1 ? false : true;
      // console.log('Portal guest: ', isGuest);
      try {
        setPortalConfig(null);
        const fetchedPortalConfig = await fetchPortalBasedOnId(
          portalId,
          isGuest
        );
        console.log('Portal config: ', fetchedPortalConfig);
        setPortalConfig(fetchedPortalConfig);
        dispatch(addActivePortal({ id: portalId }));
        if (portalState.data.portals.length === 0) {
          console.log('Activate fetch portals');
          dispatch(fetchPortalsThunk());
        }
      } catch (error) {
        // console.log((error as AxiosError));
        //triggerMessageHandler((error as AxiosError).message, true);
        // User.expireUserSession();
        if ((error as AxiosError).status === 401) {
          // console.log((error as AxiosError).request.responseURL);
          triggerMessageHandler((error as AxiosError).request.response, true);
          const matchResult = (error as AxiosError).request.responseURL.match(/\/portal\/\d+/);
          const redirectPath = matchResult ? matchResult[0] : '/'; 
          navigate(`/auth?redirect=${redirectPath}`);

        } else {
          navigate('/');
        }
      } finally {
        setIsLoading(false);
      }
    };
    fetchPortalConfig();
    // window.addEventListener(Events.IMPORT_MODAL_STATE, (customEvent: Event) => {
    //   const { detail } = customEvent as CustomEvent;
    //   dispatch(storeImportModalData({ portalId, data: detail }));
    // });
    return () => {
      setPortalConfig(null);
      dispatch(removeSelectedPortal());
    };
  }, [portalId]);

  // useEffect(() => {
  //   console.log('Additional Fields POST: ', parameterData);
  // }, [parameterData]);

  if (isLoading && !portalConfig) {
    return <Loading />;
  } else if (portalConfig !== null) {
    return (
      <>
        {title}
        <PortalApp
          portalStationConfig={portalConfig}
          isPortalStation
          defaultLanguage={i18n.language}
          customerSpecificElement={
            <Launcher
              selectedFunction={selectedFunction!}
              parameterData={parameterData}
            />
          }
          setSelectedFunction={setSelectedFunction}
          setParameterData={setParameterData}
        />
      </>
    );
  } else {
    return null;
  }
}

export default PortalRenderer;
