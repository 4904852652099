import axios from '../../../utils/api/axiosInstance';

export async function fetchCategories() {
  try {
    const response = await axios.get<{ results: [{ name: string }] }>(
      '/api/category'
    );
    // console.log(response.data);
    return response.data.results;
  } catch (error) {
    throw error;
  }
}
